import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

/**
 * Composant personnalisé pour afficher une étiquette avec la valeur directement sur le point.
 */
function CustomDotWithLabel({ cx, cy, payload, color, dataKey, otherDataKey }) {
  const value = payload[dataKey];
  const otherValue = payload[otherDataKey];
  const isAbove = value > otherValue;

  return (
    <g>
      <circle cx={cx} cy={cy} r={6} fill={color} />
      <text
        x={cx}
        y={cy}
        dy={isAbove ? -20 : 25}
        textAnchor="middle"
        fontSize={14}
        fontWeight="bold"
        fill={color}
      >
        {value.toLocaleString("fr-FR")}
      </text>
    </g>
  );
}

/**
 * Composant TimelineChart
 * Affiche un graphique des abonnés Sénat et AN.
 */
function TimelineChart({ data, comparisonKey }) {
  console.log("Données reçues dans TimelineChart :", data);

  // Vérifie si les données sont valides
  if (!Array.isArray(data) || data.length === 0) {
    return <p>Chargement des données...</p>;
  }

  // Prépare les données directement utilisables par le graphique
  const transformedData = data.map(({ Mois, Sénat, AN }) => ({
    Mois,
    Sénat,
    AN,
  }));

  // Calcul des limites pour l'axe Y
  const allValues = transformedData.flatMap(({ Sénat, AN }) => [Sénat, AN]);
  const yMin = Math.floor(Math.min(...allValues) / 10000) * 10000;
  const yMax = Math.ceil(Math.max(...allValues) / 100000) * 100000;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={transformedData}
        margin={{ top: 10, right: 30, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Mois" tick={{ fontSize: 12 }} />
        <YAxis
          domain={[yMin, yMax]}
          tickFormatter={(value) => value.toLocaleString("fr-FR")}
          tick={{ fontSize: 12 }}
        />
        <Tooltip content={<></>} />
        <Legend />

        {/* Ligne pour les données du Sénat */}
        <Line
          type="monotone"
          dataKey="Sénat"
          stroke="#d9534f"
          strokeWidth={3}
          activeDot={
            <CustomDotWithLabel
              color="#d9534f"
              dataKey="Sénat"
              otherDataKey="AN"
            />
          }
        />

        {/* Ligne pour les données de l'AN */}
        <Line
          type="monotone"
          dataKey="AN"
          stroke="#0066cc"
          strokeWidth={3}
          activeDot={
            <CustomDotWithLabel
              color="#0066cc"
              dataKey="AN"
              otherDataKey="Sénat"
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default TimelineChart;
