import React from "react";
import comparisons from "./comparisons";
import {
  calculateMonthlyChangeValue,
  calculateMonthlyChangePercentage,
  calculateYearlyChangeValue,
  calculateYearlyChangePercentage,
  calculateBase100,
} from "./calculateTrends";

function TableV2({ data, comparisonKey }) {
  console.log("Données reçues dans TableV2 :", data);

  if (!Array.isArray(data) || data.length === 0) {
    return <p>Chargement des données...</p>;
  }

  const comparisonData = comparisons[comparisonKey] || {};
  const senatKey = "Sénat";
  const anKey = "AN";

  // Définir l'index de référence pour la base 100 (premier mois de données)
  const base100ReferenceIndex = 0;

  // Calcul des données enrichies
  const enhancedData = data.map((monthData, index) => {
    const monthlySenatChangeValue = calculateMonthlyChangeValue(
      data,
      index,
      senatKey
    );
    const monthlySenatChangePercentage = calculateMonthlyChangePercentage(
      data,
      index,
      senatKey
    );
    const yearlySenatChangeValue = calculateYearlyChangeValue(
      data,
      index,
      senatKey
    );
    const yearlySenatChangePercentage = calculateYearlyChangePercentage(
      data,
      index,
      senatKey
    );

    const monthlyANChangeValue = calculateMonthlyChangeValue(
      data,
      index,
      anKey
    );
    const monthlyANChangePercentage = calculateMonthlyChangePercentage(
      data,
      index,
      anKey
    );
    const yearlyANChangeValue = calculateYearlyChangeValue(data, index, anKey);
    const yearlyANChangePercentage = calculateYearlyChangePercentage(
      data,
      index,
      anKey
    );

    const base100Senat = calculateBase100(
      data,
      base100ReferenceIndex,
      senatKey
    )[index];
    const base100AN = calculateBase100(data, base100ReferenceIndex, anKey)[
      index
    ];

    return {
      ...monthData,
      monthlySenatChangeValue,
      monthlySenatChangePercentage,
      yearlySenatChangeValue,
      yearlySenatChangePercentage,
      base100Senat,
      monthlyANChangeValue,
      monthlyANChangePercentage,
      yearlyANChangeValue,
      yearlyANChangePercentage,
      base100AN,
    };
  });

  return (
    <div>
      <h3>Tableau des abonnés</h3>
      <table className="table table-striped table-hover">
        <thead className="table-dark">
          <tr>
            <th>Mois</th>
            <th>Total {comparisonKey} Sénat</th>
            <th>Évolution Sénat (1 mois)</th>
            <th>Évolution Sénat (%)</th>
            <th>Évolution Sénat (12 mois)</th>
            <th>Évolution Sénat (%)</th>
            <th>Base 100 Sénat</th>
            <th>Total {comparisonKey} AN</th>
            <th>Évolution AN (1 mois)</th>
            <th>Évolution AN (%)</th>
            <th>Évolution AN (12 mois)</th>
            <th>Évolution AN (%)</th>
            <th>Base 100 AN</th>
          </tr>
        </thead>
        <tbody>
          {enhancedData.map((row, index) => (
            <tr key={index}>
              <td>{row.Mois}</td>
              <td>{row[senatKey]?.toLocaleString("fr-FR")}</td>
              <td>{row.monthlySenatChangeValue.toLocaleString("fr-FR")}</td>
              <td>{row.monthlySenatChangePercentage.toFixed(2)}%</td>
              <td>{row.yearlySenatChangeValue.toLocaleString("fr-FR")}</td>
              <td>{row.yearlySenatChangePercentage.toFixed(2)}%</td>
              <td>{row.base100Senat.toFixed(2)}</td>
              <td>{row[anKey]?.toLocaleString("fr-FR")}</td>
              <td>{row.monthlyANChangeValue.toLocaleString("fr-FR")}</td>
              <td>{row.monthlyANChangePercentage.toFixed(2)}%</td>
              <td>{row.yearlyANChangeValue.toLocaleString("fr-FR")}</td>
              <td>{row.yearlyANChangePercentage.toFixed(2)}%</td>
              <td>{row.base100AN.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableV2;
