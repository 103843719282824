// downloadRawData.js

import downloadData from "./downloadData";
import fetchDataGS from "../../dataOps/fetchDataGS";

// Télécharge les données brutes au format JSON
const downloadRawData = async (baseUrl, path, dataType) => {
  try {
    const rawData = await fetchDataGS(baseUrl, path); // Récupère les données brutes
    const now = new Date();
    const fileName = `raw_${dataType}_${now.toISOString().slice(0, 10)}_${now
      .toTimeString()
      .slice(0, 5)
      .replace(":", "")}.json`; // Format du fichier

    downloadData(rawData, fileName); // Déclenche le téléchargement
    console.log(`Données brutes (${dataType}) téléchargées :`, rawData);
  } catch (error) {
    console.error(
      `Erreur lors du téléchargement des données brutes (${dataType}) :`,
      error
    );
  }
};

export default downloadRawData;
