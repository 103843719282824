import { getEffectiveProxyUrl, fetchData } from "../shared/utils/fetchUtils";

const fetchDataGS = async (baseUrl, path) => {
  const proxyUrl = process.env.REACT_APP_PROXY_URL || "http://localhost:8080/";

  try {
    const effectiveUrl = getEffectiveProxyUrl(`${baseUrl}${path}`, proxyUrl);
    console.log("URL effective utilisée :", effectiveUrl);

    const rawData = await fetchData(effectiveUrl);
    console.log("Données récupérées :", rawData);
    return rawData; // Retourne uniquement les données brutes
  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
    throw error;
  }
};

export default fetchDataGS;
