// utils/fetchUtils.js

// Détermine l'environnement (local ou production) et applique un proxy si nécessaire
export const getEffectiveProxyUrl = (rawDataUrl, proxyUrl = "") => {
  const isLocal = window.location.hostname === "localhost"; // Vérifie si l'application tourne en local
  console.log("Environnement local :", isLocal);
  const effectiveProxyUrl = isLocal ? proxyUrl || "http://localhost:8080/" : ""; // Proxy local par défaut
  return `${effectiveProxyUrl}${rawDataUrl}`;
};

// Effectue une requête fetch avec gestion des erreurs
export const fetchData = async (url) => {
  try {
    console.log("Début de la récupération des données via URL :", url);
    const response = await fetch(url);
    console.log("Statut de la réponse :", response.status);

    if (!response.ok) {
      throw new Error(
        `Erreur lors de la récupération des données : ${response.status}`
      );
    }

    const data = await response.json();
    console.log("Données récupérées :", data);
    return data;
  } catch (error) {
    console.error("Erreur dans fetchData :", error);
    throw error;
  }
};
