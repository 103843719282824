import React from "react";
import logo from "../../images/tablemolle.png";

function Menu({ onSelectPage, selectedPage }) {
  return (
    <aside>
      <img
        src={logo}
        alt="logo"
        className="d-block mx-auto mb-3"
        style={{
          width: "90%",
          borderRadius: "20px",
        }}
      />
      <nav>
        <ul className="list-unstyled">
          <li
            onClick={() => onSelectPage("Accueil")}
            className={`mb-2 ${
              selectedPage === "Accueil" ? "fw-bold text-primary" : ""
            }`}
          >
            Accueil
          </li>
          <li
            onClick={() => onSelectPage("Tests API")}
            className={`mb-2 ${
              selectedPage === "Tests API" ? "fw-bold text-primary" : ""
            }`}
          >
            Tests API
          </li>
          <li
            onClick={() => onSelectPage("Abonnés")}
            className={`mb-2 ${
              selectedPage === "Abonnés" ? "fw-bold text-primary" : ""
            }`}
          >
            Abonnés
          </li>
          <li
            onClick={() => onSelectPage("Abonnés old")}
            className={`mb-2 ${
              selectedPage === "Abonnés old" ? "fw-bold text-primary" : ""
            }`}
          >
            Abonnés old
          </li>
          <li
            onClick={() => onSelectPage("Publications")}
            className={`mb-2 ${
              selectedPage === "Publications" ? "fw-bold text-primary" : ""
            }`}
          >
            Publications
          </li>
          <li
            onClick={() => onSelectPage("Vues")}
            className={`mb-2 ${
              selectedPage === "Vues" ? "fw-bold text-primary" : ""
            }`}
          >
            Vues
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Menu;
