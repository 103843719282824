import React from "react";

/**
 * Composant pour afficher le résumé global des abonnés
 * @param {Object[]} comparisonData - Données préparées pour comparaison
 * @param {string} comparisonKey - Clé de comparaison sélectionnée
 */
const AbonnesSummary = ({ comparisonData, comparisonKey }) => {
  if (!comparisonData || comparisonData.length === 0) return null;

  const latestMonth = comparisonData[comparisonData.length - 1]; // Dernier mois disponible
  const formattedMonth = latestMonth.Mois; // Format "Dec 24", par exemple

  return (
    <div>
      <h5>Résumé Global</h5>
      <p>
        {comparisonKey} Sénat ({formattedMonth}) :{" "}
        <strong>{latestMonth.Sénat.toLocaleString("fr-FR")}</strong>
        <br />
        {comparisonKey} AN ({formattedMonth}) :{" "}
        <strong>{latestMonth.AN.toLocaleString("fr-FR")}</strong>
      </p>
    </div>
  );
};

export default AbonnesSummary;
