import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import fetchDataGS from "../dataOps/fetchDataGS";
import transformDataGSMVues from "../dataOps/transformData_GSM_vues";
import downloadRawData from "../shared/utils/downloadRawData";
import downloadTransformedData from "../shared/utils/downloadTransformedData";

function Vues() {
  const [displayData, setDisplayData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Infos API pour les vues mensuelles (cf. TestApis.js)
  const baseUrl = "https://1npd7l897a.execute-api.eu-west-3.amazonaws.com/dev";
  const path = "/mensuel/vues";

  // Fonction pour récupérer et transformer les données
  const fetchAndTransformData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      // Récupération des données brutes
      const rawData = await fetchDataGS(baseUrl, path);

      // Transformation des données
      const transformedData = await transformDataGSMVues(
        process.env.REACT_APP_PROXY_URL || "http://localhost:8080/",
        rawData
      );

      // Stockage des données transformées
      setDisplayData(transformedData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération/ transformation des données :",
        error
      );
      setError("Une erreur s'est produite lors du chargement des données.");
    } finally {
      setLoading(false);
    }
  }, [baseUrl, path]);

  useEffect(() => {
    fetchAndTransformData();
  }, [fetchAndTransformData]);

  return (
    <div className="mt-4">
      <h2>Page Vues</h2>

      {/* Indicateurs de chargement ou d'erreur */}
      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Boutons de téléchargement */}
      <div className="mt-3">
        <Button
          className="me-2"
          onClick={() => downloadRawData(baseUrl, path, "vues")}
        >
          Raw JSON
        </Button>
        <Button
          className="me-2"
          onClick={() =>
            downloadTransformedData(baseUrl, path, "vues", (rawData) =>
              transformDataGSMVues(
                process.env.REACT_APP_PROXY_URL || "http://localhost:8080/",
                rawData
              )
            )
          }
        >
          Transformed JSON
        </Button>
      </div>

      {/* Prévisualisation des données */}
      <div className="mt-4">
        <h5>Prévisualisation des données</h5>
        <pre className="data-preview">
          {displayData
            ? JSON.stringify(displayData, null, 2).slice(0, 500)
            : "Aucune donnée disponible"}
        </pre>
      </div>
    </div>
  );
}

export default Vues;
