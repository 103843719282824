import React from "react";
//import TableauGS from "../RS/tests/TableauGS";
import TestApis from "../RS/tests/TestApis";
import Abonnes from "../RS/abonnes/Abonnes";
import AbonnesOld from "../RS/abonnes/AbonnesOld";
import Vues from "../RS/vues/Vues";

function Content({ page }) {
  const renderContent = () => {
    switch (page) {
      case "Accueil":
        return <p className="text-center">Bienvenue sur la page d'accueil !</p>;
      case "Tests API":
        return <TestApis />;
      case "Abonnés":
        return <Abonnes />;
      case "Abonnés old":
        return <AbonnesOld />;
      case "Publications":
        return <p className="text-center">Contenu pour les Publications</p>;
      case "Vues":
        return <Vues />;
      default:
        return <p className="text-center text-danger">Page non trouvée</p>;
    }
  };

  return <main>{renderContent()}</main>;
}

export default Content;
