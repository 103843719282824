import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react"; // pas utilisé en localhost

import Menu from "./components/layout/Menu";
import Content from "./components/layout/Content";
import "./styles/global.css";
import "./styles/senat.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap importé ici si nécessaire

Amplify.configure(awsconfig);

console.log("REPERE BEGIN");
console.log("Valeur REACT_APP_PROXY_URL :", process.env.REACT_APP_PROXY_URL);
console.log("REPERE END");

function App() {
  // Ajout de l'état pour gérer la page sélectionnée
  const [selectedPage, setSelectedPage] = useState("Accueil");

  // Fonction pour mettre à jour la page en fonction de la sélection
  const handleSelectPage = (page) => {
    setSelectedPage(page);
  };

  return (
    <div className="app-container container-fluid">
      <div className="row">
        {/* Passer handleSelectPage et selectedPage à Menu pour gérer la navigation */}
        <div className="col-3 bg-light p-3">
          <Menu onSelectPage={handleSelectPage} selectedPage={selectedPage} />
        </div>

        {/* Passer la page sélectionnée à Content pour afficher le bon contenu */}
        <div className="col-9">
          <Content page={selectedPage} />
        </div>
      </div>
    </div>
  );
}

// export default withAuthenticator(App);
export default App;
