// dateUtils.js

/**
 * Convertit une chaîne de format "MMM YY" (par ex. : "Sep 23") en un objet Date UTC correspondant au 1er jour du mois.
 * @param {string} monthYear - La chaîne représentant le mois et l'année (ex. : "Sep 23").
 * @returns {Date} - Un objet Date UTC.
 * @throws {Error} - Si l'entrée est invalide.
 */
export const convertMonthYearToDate = (monthYear) => {
  const [monthStr, yearSuffix] = (monthYear || "").trim().split(/\s+/); // Extraction du mois et de l'année
  const monthIndex = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ].indexOf(monthStr);

  // Vérification des entrées
  if (monthIndex === -1 || isNaN(parseInt(yearSuffix, 10))) {
    throw new Error(`Format invalide : "${monthYear}"`);
  }

  const year = parseInt(`20${yearSuffix}`, 10); // Convertit "23" en "2023"
  return new Date(Date.UTC(year, monthIndex, 1)); // Retourne le 1er du mois en UTC
};
