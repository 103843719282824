// downloadTransformedData.js

import downloadData from "./downloadData";
import fetchDataGS from "../../dataOps/fetchDataGS";

// Télécharge les données transformées au format JSON
const downloadTransformedData = async (
  baseUrl,
  path,
  dataType,
  transformFunction
) => {
  try {
    const rawData = await fetchDataGS(baseUrl, path); // Récupère les données brutes
    const transformedData = await transformFunction(rawData); // Transforme les données

    const now = new Date();
    const fileName = `transformed_${dataType}_${now
      .toISOString()
      .slice(0, 10)}_${now.toTimeString().slice(0, 5).replace(":", "")}.json`; // Format du fichier

    downloadData(transformedData, fileName); // Déclenche le téléchargement
    console.log(
      `Données transformées (${dataType}) téléchargées :`,
      transformedData
    );
  } catch (error) {
    console.error(
      `Erreur lors du téléchargement des données transformées (${dataType}) :`,
      error
    );
  }
};

export default downloadTransformedData;
