// calculateTrends.js

/**
 * Calcule l'évolution en nombre d'abonnés sur 1 mois.
 * @param {Array} data - Tableau des données (triées par date croissante).
 * @param {number} index - Index du mois pour lequel on calcule l'évolution.
 * @param {string} key - Clé pour les données ("AN" ou "Sénat").
 * @returns {number} - Évolution en nombre sur 1 mois.
 */
export const calculateMonthlyChangeValue = (data, index, key) => {
  if (index === 0) return 0; // Pas d'évolution pour le premier mois
  return data[index][key] - data[index - 1][key];
};

/**
 * Calcule l'évolution en pourcentage sur 1 mois.
 * @param {Array} data - Tableau des données.
 * @param {number} index - Index du mois pour lequel on calcule l'évolution.
 * @param {string} key - Clé pour les données ("AN" ou "Sénat").
 * @returns {number} - Évolution en pourcentage sur 1 mois.
 */
export const calculateMonthlyChangePercentage = (data, index, key) => {
  if (index === 0 || data[index - 1][key] === 0) return 0;
  const change = calculateMonthlyChangeValue(data, index, key);
  return (change / data[index - 1][key]) * 100;
};

/**
 * Calcule l'évolution en nombre d'abonnés sur 12 mois.
 * @param {Array} data - Tableau des données.
 * @param {number} index - Index du mois pour lequel on calcule l'évolution.
 * @param {string} key - Clé pour les données ("AN" ou "Sénat").
 * @returns {number} - Évolution en nombre sur 12 mois (ou 0 si données indisponibles).
 */
export const calculateYearlyChangeValue = (data, index, key) => {
  if (index < 12) return 0; // Pas d'évolution si moins de 12 mois de données
  return data[index][key] - data[index - 12][key];
};

/**
 * Calcule l'évolution en pourcentage sur 12 mois.
 * @param {Array} data - Tableau des données.
 * @param {number} index - Index du mois pour lequel on calcule l'évolution.
 * @param {string} key - Clé pour les données ("AN" ou "Sénat").
 * @returns {number} - Évolution en pourcentage sur 12 mois.
 */
export const calculateYearlyChangePercentage = (data, index, key) => {
  if (index < 12 || data[index - 12][key] === 0) return 0;
  const change = calculateYearlyChangeValue(data, index, key);
  return (change / data[index - 12][key]) * 100;
};

/**
 * Calcule l'évolution en base 100 (par rapport à une date de référence).
 * @param {Array} data - Tableau des données.
 * @param {number} referenceIndex - Index de la date de référence.
 * @param {string} key - Clé pour les données ("AN" ou "Sénat").
 * @returns {Array} - Tableau des valeurs en base 100 pour chaque mois.
 */
export const calculateBase100 = (data, referenceIndex, key) => {
  const referenceValue = data[referenceIndex]?.[key];
  if (!referenceValue || referenceValue === 0) return data.map(() => 0);

  return data.map((monthData) => (monthData[key] / referenceValue) * 100);
};
