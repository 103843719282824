const transformDataGSMAbonnes = async (proxyUrl, rawData) => {
  try {
    // Déterminer l'environnement (local ou production)
    const isLocal = window.location.hostname === "localhost"; // Vérifie si l'application tourne en local
    console.log("Environnement local pour transformDataGSMAbonnes :", isLocal);

    // Si en local, utilisez le proxy. En production, pas de proxy
    const effectiveProxyUrl = isLocal ? proxyUrl || "" : ""; // Pas de proxy en production
    console.log(
      "Proxy effectif utilisé pour transformDataGSMAbonnes :",
      effectiveProxyUrl
    );

    // URL de l'API pour transformer les données
    const transformedApiUrl = `${effectiveProxyUrl}https://hq6fg538c3.execute-api.eu-west-3.amazonaws.com/dev/transform_abonnes`;
    console.log(
      "URL finale pour l'appel à transform_abonnes :",
      transformedApiUrl
    );

    // Appel à l'API de transformation
    const response = await fetch(transformedApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(rawData), // Envoi des données brutes
    });

    if (!response.ok) {
      throw new Error(
        `Erreur lors de la transformation des données : ${response.status}`
      );
    }

    const transformedData = await response.json();
    console.log("Données transformées reçues :", transformedData);

    return transformedData; // Retourne les données transformées
  } catch (error) {
    console.error("Erreur lors de la transformation des données :", error);
    throw error;
  }
};

export default transformDataGSMAbonnes;
