const comparisons = {
  "Total abonnés": {
    AN: "TOTAL AN",
    Sénat: "TOTAL ABONNES (Sénat + Jardin)",
  },
  "X/Twitter": {
    AN: "Twitter AN",
    Sénat: ["Twitter Sénat", "Twitter Senat_Direct", "Twitter JardinLuco"], // faire la somme
  },
  LinkedIn: {
    AN: "LinkedIn AN",
    Sénat: "LinkedIn",
  },
  YouTube: {
    AN: "YouTube AN",
    Sénat: "YouTube",
  },
  Instagram: {
    AN: "Insta AN",
    Sénat: ["Insta Sénat", "Insta Jardin"],
  },
  Tiktok: {
    AN: null, // Pas de données pour l'AN
    Sénat: "TikTok",
  },
  Facebook: {
    AN: "Facebook AN",
    Sénat: "Facebook",
  },
};

export default comparisons;
