import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import fetchDataGS from "../dataOps/fetchDataGS";
import transformDataGSMAbonnes from "../dataOps/transformData_GSM_abonnes"; // Transformation pour les abonnés
import downloadData from "../shared/utils/downloadData";

/**
 * Composant pour un bloc de test d'API (ex : Abonnés, Vues, etc.).
 * Gère les clics sur les boutons "Raw JSON" et "Transformed JSON".
 */
function ApiTestBlock({
  testName,
  baseUrl,
  path,
  transformData,
  setDisplayData,
}) {
  // Gestion du clic sur "Download Raw JSON"
  const handleRawJsonClick = async () => {
    try {
      console.log(`Début de la récupération RAW pour : ${testName}`);
      // Appelle fetchDataGS avec les paramètres de baseUrl et path
      const rawData = await fetchDataGS(baseUrl, path);
      if (rawData) {
        // Génère un nom de fichier au format demandé
        const now = new Date();
        const date = now.toISOString().slice(0, 10); // Format YYYY-MM-DD
        const time = now.toTimeString().slice(0, 5).replace(":", ""); // Format HHMM
        const fileName = `raw_${testName.replace(
          /\s/g,
          "_"
        )}_${date}_${time}.json`;

        // Télécharge les données brutes sous forme de fichier JSON
        downloadData(rawData, fileName);
        console.log(`RAW téléchargé pour ${testName} :`, rawData);
        // Met à jour les données affichées pour prévisualisation
        setDisplayData(rawData);
      }
    } catch (error) {
      console.error(
        `Erreur lors de la récupération RAW pour ${testName} :`,
        error
      );
    }
  };

  // Gestion du clic sur "Download Transformed JSON"
  const handleTransformedJsonClick = async () => {
    try {
      console.log(`Début de la transformation pour : ${testName}`);
      // Appelle fetchDataGS pour récupérer les données brutes
      const rawData = await fetchDataGS(baseUrl, path);
      if (!rawData) {
        console.error(`Données brutes manquantes pour ${testName}`);
        return;
      }

      // Appelle la fonction de transformation spécifique
      const transformedData = await transformData(rawData);
      if (transformedData) {
        // Génère un nom de fichier au format demandé
        const now = new Date();
        const date = now.toISOString().slice(0, 10); // Format YYYY-MM-DD
        const time = now.toTimeString().slice(0, 5).replace(":", ""); // Format HHMM
        const fileName = `transformed_${testName.replace(
          /\s/g,
          "_"
        )}_${date}_${time}.json`;

        // Télécharge les données transformées sous forme de fichier JSON
        downloadData(transformedData, fileName);
        console.log(
          `TRANSFORMED téléchargé pour ${testName} :`,
          transformedData
        );
        // Met à jour les données affichées pour prévisualisation
        setDisplayData(transformedData);
      }
    } catch (error) {
      console.error(
        `Erreur lors de la transformation pour ${testName} :`,
        error
      );
    }
  };

  return (
    <div className="d-flex align-items-center mb-3">
      <span className="me-3">
        Données : <strong>{testName}</strong>
      </span>
      <Button className="me-2" onClick={handleRawJsonClick}>
        Raw JSON
      </Button>
      <Button onClick={handleTransformedJsonClick}>Transformed JSON</Button>
    </div>
  );
}

/**
 * Composant principal pour tester les APIs via des blocs configurables.
 */
function TestApis() {
  const [displayData, setDisplayData] = useState(null); // Prévisualisation du dernier JSON cliqué

  const baseUrl = "https://1npd7l897a.execute-api.eu-west-3.amazonaws.com/dev";

  // Configuration des tests mensuels et hebdomadaires
  const tests = [
    // **Tests mensuels**
    {
      testName: "GS Mensuel - Chiffres-clés",
      path: "/mensuel",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Mensuel - Chiffres-clés :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Mensuel - Abonnés",
      path: "/mensuel/abonnes",
      transformData: (rawData) =>
        transformDataGSMAbonnes(
          process.env.REACT_APP_PROXY_URL || "http://localhost:8080/",
          rawData
        ), // Transformation spécifique pour les abonnés
    },
    {
      testName: "GS Mensuel - Vues",
      path: "/mensuel/vues",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Mensuel - Vues :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Mensuel - Vues Insta",
      path: "/mensuel/vuesINSTA",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Mensuel - Vues Insta :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Mensuel - Vues Linkedin",
      path: "/mensuel/vuesLINK",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Mensuel - Vues Linkedin :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Mensuel - Presse",
      path: "/mensuel/presse",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Mensuel - Presse :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },

    // **Tests hebdomadaires**
    {
      testName: "GS Hebdo - Chiffres-clés",
      path: "/hebdo",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Hebdo - Chiffres-clés :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Hebdo - Vues et impressions",
      path: "/hebdo/impressions",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Hebdo - Vues et impressions :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Hebdo - Vues Insta",
      path: "/hebdo/vuesINSTA",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Hebdo - Vues Insta :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
    {
      testName: "GS Hebdo - Vues Linkedin",
      path: "/hebdo/vuesLINK",
      transformData: async (rawData) => {
        console.log(
          "Transformation (placeholder) pour GS Hebdo - Vues :",
          rawData
        );
        return rawData; // Placeholder pour transformation
      },
    },
  ];

  return (
    <div className="container mt-4">
      <h2>Test APIs</h2>
      {tests.map((test, index) => (
        <ApiTestBlock
          key={index}
          testName={test.testName}
          baseUrl={baseUrl}
          path={test.path}
          transformData={test.transformData}
          setDisplayData={setDisplayData}
        />
      ))}
      <div>
        <h5>Prévisualisation des données :</h5>
        <pre
          style={{
            backgroundColor: "#f8f9fa",
            padding: "10px",
            borderRadius: "5px",
            overflow: "auto",
            maxHeight: "200px",
          }}
        >
          {displayData
            ? JSON.stringify(displayData, null, 2).slice(0, 500)
            : "Aucune donnée disponible"}
        </pre>
      </div>
    </div>
  );
}

export default TestApis;
