/**
 * Extrait les données de comparaison Sénat/AN pour un mois donné.
 * @param {Object} monthData - Données pour un mois spécifique.
 * @param {string} comparisonKey - La clé de comparaison utilisée.
 * @param {Object} comparisons - L'objet contenant les configurations de comparaison.
 * @returns {Object} Les valeurs Sénat et AN pour le mois donné.
 */
const extractComparisonData = (monthData, comparisonKey, comparisons) => {
  const comparisonData = comparisons[comparisonKey] || {};
  const senatKeys = Array.isArray(comparisonData.Sénat)
    ? comparisonData.Sénat
    : [comparisonData.Sénat];
  const anKey = comparisonData.AN;

  // Calcul de la somme des valeurs Sénat si plusieurs clés sont spécifiées
  const senatValue = senatKeys.reduce((sum, key) => {
    return sum + (monthData.plateformes[key]?.abonnés || 0);
  }, 0);

  const anValue = monthData.plateformes[anKey]?.abonnés || 0;

  return {
    Sénat: senatValue,
    AN: anValue,
  };
};

/**
 * Prépare les données pour la comparaison entre Sénat et AN sur plusieurs mois.
 * @param {Array} displayData - Les données transformées à partir de l'API.
 * @param {string} comparisonKey - La clé utilisée pour la comparaison.
 * @param {Object} comparisons - L'objet contenant les configurations de comparaison.
 * @returns {Array} Les données formatées pour les graphiques ou tableaux.
 */
const prepareComparisonData = (displayData, comparisonKey, comparisons) => {
  return displayData.map((monthData) => {
    const comparisonData = extractComparisonData(
      monthData,
      comparisonKey,
      comparisons
    );
    return {
      Mois: monthData.Mois,
      AN: comparisonData.AN,
      Sénat: comparisonData.Sénat,
    };
  });
};

export { prepareComparisonData, extractComparisonData };
