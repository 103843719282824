import React, { useState, useEffect, useCallback } from "react";
import downloadRawData from "../shared/utils/downloadRawData";
import downloadTransformedData from "../shared/utils/downloadTransformedData";
import fetchDataGS from "../dataOps/fetchDataGS";
import transformDataGSMAbonnes from "../dataOps/transformData_GSM_abonnes";
import { convertMonthYearToDate } from "../shared/utils/dateUtils";
import TableV2 from "./table_v2";
import TimelineChart from "./timeline_chart_v2";
import Button from "react-bootstrap/Button";
import comparisons from "./comparisons";
import { prepareComparisonData } from "./comparisonsAbonnesUtils";
import AbonnesSummary from "./abonnesSummary";

// Composant principal
function Abonnes() {
  // État local
  const [displayData, setDisplayData] = useState(null); // Données transformées
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [error, setError] = useState(null); // Gestion des erreurs
  const [comparisonKey, setComparisonKey] = useState("Total abonnés"); // Clé de comparaison sélectionnée

  // Informations générales pour l'API
  const baseUrl = "https://1npd7l897a.execute-api.eu-west-3.amazonaws.com/dev";
  const path = "/mensuel/abonnes";
  const proxyUrl = process.env.REACT_APP_PROXY_URL || "http://localhost:8080/";

  // Fonction de récupération et transformation des données (stabilisée avec useCallback)
  const fetchAndTransformData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      // Récupération des données brutes
      const rawData = await fetchDataGS(baseUrl, path);

      // Transformation des données avec la fonction dédiée
      const transformedData = await transformDataGSMAbonnes(proxyUrl, rawData);

      // Détermine la date actuelle pour filtrage
      const currentDate = new Date();

      // Transformation des données pour inclure la conversion des mois/années
      const transformedArray = Object.entries(transformedData)
        .map(([month, details]) => {
          try {
            const monthDate = convertMonthYearToDate(month); // Utilisation de l'utilitaire pour convertir les mois/années
            return {
              Mois: month,
              ...details,
              _monthDate: monthDate, // Ajout de la date pour le filtrage
            };
          } catch {
            return null; // Ignore les entrées invalides
          }
        })
        .filter(Boolean) // Supprime les nulls
        .filter(({ _monthDate }) => _monthDate <= currentDate); // Filtre les dates futures

      // Met à jour les données affichées
      setDisplayData(transformedArray);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération ou transformation des données :",
        error
      );
      setError("Une erreur s'est produite lors du chargement des données.");
    } finally {
      setLoading(false); // Arrête l'indicateur de chargement
    }
  }, [baseUrl, path, proxyUrl]);

  // Exécution de la récupération des données au montage du composant
  useEffect(() => {
    fetchAndTransformData();
  }, [fetchAndTransformData]);

  // Préparation des données pour le tableau et le graphique
  const comparisonData = displayData
    ? prepareComparisonData(displayData, comparisonKey, comparisons)
    : null;

  // Rendu du composant
  return (
    <div className="mt-4">
      <h2>Page Abonnés</h2>

      {/* Indicateurs de chargement ou d'erreur */}
      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      <div className="row gx-0">
        {/* Colonne gauche : résumé global et boutons */}
        <div className="col-6">
          <AbonnesSummary
            comparisonData={comparisonData}
            comparisonKey={comparisonKey}
          />
          <div className="mt-3">
            <label htmlFor="comparisonSelect">
              <strong>Comparer :</strong>
            </label>
            <select
              id="comparisonSelect"
              className="form-select w-auto d-inline ms-2"
              value={comparisonKey}
              onChange={(e) => setComparisonKey(e.target.value)}
            >
              {Object.keys(comparisons).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <Button
              className="me-2"
              onClick={() => downloadRawData(baseUrl, path, "abonnes")}
            >
              Raw JSON
            </Button>
            <Button
              className="me-2"
              onClick={() =>
                downloadTransformedData(baseUrl, path, "abonnes", (rawData) =>
                  transformDataGSMAbonnes(proxyUrl, rawData)
                )
              }
            >
              Transformed JSON
            </Button>
          </div>
        </div>

        {/* Colonne droite : prévisualisation */}
        <div className="col-6">
          <h5>Prévisualisation des données</h5>
          <pre className="data-preview">
            {JSON.stringify(displayData, null, 2).slice(0, 500)}
          </pre>
        </div>
      </div>

      {/* Ligne : graphique */}
      <div className="mt-4">
        <div className="chart-container">
          {comparisonData && (
            <TimelineChart
              data={comparisonData}
              comparisonKey={comparisonKey}
            />
          )}
        </div>
      </div>

      {/* Ligne : tableau */}
      <div className="mt-4">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          {comparisonData && (
            <TableV2 data={comparisonData} comparisonKey={comparisonKey} />
          )}
        </div>
      </div>
      {/* fin des lignes de la page */}
    </div>
  );
}

export default Abonnes;
